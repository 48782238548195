@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label {
  font-family: "Raleway", sans-serif;
}

button {
  @apply bg-primary/80 shadow-xl shadow-primary/20 py-3 px-5 text-white font-bold tracking-widest;
}

.quicksand,
button {
  font-family: "Quicksand", sans-serif;
}

.rotate-2 {
  transform: matrix(1, -0.08, 0.11, 0.99, 0, 0);
}
.rotate-1 {
  /* transform: matrix(-1, -0.08, -0.11, 0.99, 0, -30); */
  transform: matrix(1, 0.1, 0.1, 1, 0, 30);
}
